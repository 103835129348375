import { AxiosError } from "axios";
import { log } from "common/Logger";
import { useErrorLogger } from "common/LoggerHooks";
import { useContext } from "react";
import { UseQueryResult, useQuery } from "react-query";
import { ApiContext } from "services/ApiContext";
import { Vorgang } from "./VorgangTypes";

export const useGetVorgaenge = (includeCancelled: boolean): UseQueryResult<Array<Vorgang>, AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetVorgaenge = async ({ queryKey }): Promise<Array<Vorgang>> => {
    const [, includeCancelled]: [never, boolean] = queryKey;
    const response = await api.get("/Vorgaenge?includeCancelled=" + includeCancelled);
    log.trace({ obj: response.data }, "result from get vorgaenge");

    return response.data;
  };

  return useQuery<Array<Vorgang>, AxiosError>({
    queryKey: [VorgangQueryKeys.VorgangListe, includeCancelled],
    queryFn: GetVorgaenge,
    onError: (err) => logAxiosError("Error loading Vorgänge", "Fehler beim Laden der Vorgänge", err),
  });
};

export const useGetVorgang = (id: string): UseQueryResult<Vorgang, AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetVorgang = async ({ queryKey }): Promise<Vorgang> => {
    const [, id]: [never, string] = queryKey;
    const response = await api.get("/Vorgaenge/" + id);
    log.trace({ obj: response.data }, "result from get vorgang");

    return response.data;
  };

  return useQuery<Vorgang, AxiosError>({
    queryKey: [VorgangQueryKeys.Vorgang, id],
    queryFn: GetVorgang,
    onError: (err) => logAxiosError("Error loading Vorgang", "Fehler beim Laden des Vorgangs", err),
  });
};

export const VorgangQueryKeys = {
  VorgangListe: "vorgaenge",
  Vorgang: "vorgang",
  AnzahlVorgaenge: "anzahlVorgaenge",
} as const;
