import React, { useContext } from "react";
import { Button, Jumbotron, Row, Col, Container } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { AuthContext } from "../services/authentication";

import spash_background from "../assets/splash_background.jpg";

const Splash: React.FC = () => {
  // hooks
  const authContext = useContext(AuthContext);

  // states
  // parameter
  // queries und mutationen
  // effekte
  // daten
  // handler

  const backgroundStyles: React.CSSProperties = {
    backgroundImage: `url(${spash_background})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
  };

  return (
    <div style={backgroundStyles} className="h-100">
      <Container>
        <Row style={{ marginTop: "10rem" }}>
          <Col md={8} lg={6}>
            <Jumbotron>
              <h1 className="display-4">
                Herzlich Willkommen im <strong>Glasfaser TK</strong>Portal
              </h1>
              <p>
                Willkommen auf dem <strong>Glasfaser TK</strong>Portal der GELSENWASSER AG. Wir sind das Team blau-grün. Als
                Partner von Städten und Kommunen bieten wir Lösungen für alle Aufgaben und Herausforderungen der
                Infrastruktur.
              </p>
              <Button variant="secondary" onClick={authContext.signinRedirect}>
                {" "}
                <BsArrowRight className="text-primary" /> Anmelden
              </Button>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Splash;
