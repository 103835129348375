import { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";
import { log } from "../../common/Logger";
import Benutzer, { UserData } from "./UserTypes";
import { useErrorLogger } from "common/LoggerHooks";
import { useContext } from "react";
import { ApiContext } from "services/ApiContext";
import { AuthContext } from "services/authentication";

export const useGetUsers = (): UseQueryResult<Array<Benutzer>, AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetUsers = async (): Promise<Array<Benutzer>> => {
    const response = await api.get("Benutzer");
    log.trace({ obj: response.data }, "result from get benutzer");
    return response.data;
  };

  return useQuery<Array<Benutzer>, AxiosError>({
    queryKey: BenutzerQueryKeys.BenutzerListe,
    queryFn: GetUsers,
    onError: (err) => logAxiosError("Error loading Benutzer", "Fehler beim Laden der Benutzer", err),
  });
};

export const useGetUserData = (): UseQueryResult<UserData, AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);
  const authContext = useContext(AuthContext);
  const isAuthenticated = authContext.isAuthenticated();

  const getUserData = async (): Promise<UserData> => {
    const response = await api.get("/Benutzer/me");
    log.trace({ obj: response.data }, "result from get 'me'");
    return response.data;
  };

  return useQuery<UserData, AxiosError>({
    queryKey: "mycompany",
    queryFn: getUserData,
    enabled: isAuthenticated,
    onError: (err) => logAxiosError("Error loading MyCompany", "Fehler beim Laden meines Unternehmens", err),
    staleTime: 15 * 60 * 1000 /* 15 Minuten, Default: 0 Minuten */,
  });
};

export const BenutzerQueryKeys = {
  BenutzerListe: "benutzer",
} as const;
