import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { useErrorLogger } from "common/LoggerHooks";
import { FeatureCollection, GeoJsonObject } from "geojson";
import { useContext } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { ApiContext } from "services/ApiContext";
import {
  AnschlussMarker,
  CustomLayer,
  GeoLayer,
  NominatimResponse,
  NominatimReverseResponse,
  Ort,
} from "./AuswahlTypes";
import { log } from "../../common/Logger";
import { LatLng } from "leaflet";

export const GebietsauswahlQueryKeys = {
  MarkerListe: "marker",
  EigeneGebiete: "ownAreas",
  EigeneGebieteGeoData: "ownAreasGeoData",
  AndereGebieteGeoData: "otherAreasGeoData",
  Overlays: "overlays",
  AnzahlGebiete: "anzahlGebiete",
  Orte: "orte",
  LayersGeoData: "layersGeoData",
  AddressLocation: "addressLocation",
} as const;

export const useGetMarkers = (ortId: string | undefined): UseQueryResult<Array<AnschlussMarker>, AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetMarkers = async ({ queryKey }): Promise<Array<AnschlussMarker>> => {
    const [, ortId]: [never, string] = queryKey;
    if (ortId) {
      const response = await api.get("/Anschluesse/" + ortId);
      log.trace({ obj: response.data }, "result from get anschluesse");
      return response.data as AnschlussMarker[];
    }
    return [];
  };

  return useQuery<Array<AnschlussMarker>, AxiosError>({
    queryKey: [GebietsauswahlQueryKeys.MarkerListe, ortId],
    queryFn: GetMarkers,
    onError: (err) => logAxiosError("Error loading Marker", "Fehler beim Laden der Marker", err),
  });
};

export const useGetAreasGeoData = (
  mode: "own" | "other",
  ortId: string | undefined,
  isGebietsauswahl: boolean
): UseQueryResult<FeatureCollection, AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetAreasGeoData = async ({ queryKey }): Promise<FeatureCollection> => {
    const [, ortId]: [never, string] = queryKey;
    if (ortId) {
      const response = await api.get("/Gebiete/Geodata/" + ortId + "/" + mode);
      log.trace({ obj: response.data }, `result from get ${mode} gebiete`);
      return response.data as FeatureCollection;
    }
    return { type: "FeatureCollection", features: [] };
  };

  const modeTranslation = mode == "own" ? "eigenen" : "anderen";

  return useQuery<FeatureCollection, AxiosError>({
    queryKey: [
      mode == "own" ? GebietsauswahlQueryKeys.EigeneGebieteGeoData : GebietsauswahlQueryKeys.AndereGebieteGeoData,
      ortId,
    ],
    enabled: isGebietsauswahl,
    queryFn: GetAreasGeoData,
    onError: (err) =>
      logAxiosError(`Error loading ${mode} Gebiete`, `Fehler beim Laden der ${modeTranslation} Gebiete`, err),
  });
};

export const useGetOverlays = (): UseQueryResult<CustomLayer[], AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetOverlays = async (): Promise<CustomLayer[]> => {
    const response = await api.get("/Layer");
    log.trace({ obj: response.data }, "result from get Overlays");
    return response.data as CustomLayer[];
  };

  return useQuery<CustomLayer[], AxiosError>({
    queryKey: GebietsauswahlQueryKeys.Overlays,
    queryFn: GetOverlays,
    onError: (err) => logAxiosError("Error loading Overlays", "Fehler beim Laden der Overlays", err),
  });
};

export const useGetAddress = (position: LatLng | undefined): UseQueryResult<NominatimReverseResponse, AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const SearchForAddress = async ({ queryKey }): Promise<NominatimReverseResponse> => {
    const [, position]: [never, LatLng] = queryKey;
    log.debug({ obj: position }, "searching for position");
    const response = await api.get<NominatimReverseResponse>(
      `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${position.lat}&lon=${position.lng}`
    );
    log.trace({ obj: response.data }, "result from get SearchForAddress");
    return response.data;
  };

  return useQuery<NominatimReverseResponse, AxiosError>({
    queryKey: ["Standort", position],
    queryFn: SearchForAddress,
    enabled: position !== undefined,
    onError: (err) =>
      logAxiosError("Error location address for map", "Fehler beim Suchen der Adresse für die Kartendarstellung", err),
    staleTime: 15 * 60 * 1000 /* 15 Minuten, Default: 0 Minuten */,
  });
};

export const useGetAddressLocation = (
  position: string | undefined,
  ort: string | undefined
): UseQueryResult<NominatimResponse[], AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const SearchForAddressLocation = async ({ queryKey }): Promise<NominatimResponse[]> => {
    const [, position]: [never, LatLng] = queryKey;
    log.debug({ obj: position }, "searching for position");
    const response = await api.get<NominatimResponse[]>(
      `https://nominatim.openstreetmap.org/search?street=${position}&city=${ort}&format=json&addressdetails=1&polygon_geojson=1`
    );
    log.trace({ obj: response.data }, "result from get SearchForAddressLocation");
    return response.data;
  };

  return useQuery<NominatimResponse[], AxiosError>({
    queryKey: [GebietsauswahlQueryKeys.AddressLocation, position],
    queryFn: SearchForAddressLocation,
    enabled: position !== undefined,
    onError: (err) =>
      logAxiosError("Error searching address on map", "Fehler beim Suchen der Adresse für die Kartendarstellung", err),
    //staleTime: 15 * 60 * 1000 /* 15 Minuten, Default: 0 Minuten */,
  });
};

export const useGetOrte = (): UseQueryResult<Ort[], AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetOrte = async (): Promise<Ort[]> => {
    const response = await api.get<Ort[]>("/Orte");
    log.trace({ obj: response.data }, "result from get Orte");
    return response.data;
  };

  return useQuery<Ort[], AxiosError>({
    queryKey: GebietsauswahlQueryKeys.Orte,
    queryFn: GetOrte,
    onError: (err) => logAxiosError("Error loading Orte", "Fehler beim Laden der Orte", err),
  });
};

export const useGetLayersGeoData = (daten: CustomLayer[]): UseQueryResult<GeoLayer[], AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const api = axios.create();

  const GetLayersGeoData = async (api: AxiosInstance, daten: CustomLayer[]) => {
    const geolayerTasks: Array<Promise<AxiosResponse<GeoJsonObject>>> = [];

    log.debug("Getting LayersGeoData");

    daten.forEach((x) => {
      geolayerTasks.push(api.get<GeoJsonObject>(x.url));
    });

    const response = await Promise.allSettled(geolayerTasks);
    log.trace({ obj: response }, "result from get geojsonobject");
    const layers: GeoLayer[] = [];

    response.forEach((elemt) => {
      if (elemt.status === "fulfilled") {
        layers.push({
          ...daten.find((x) => x.url === elemt.value.config.url),
          geojson: elemt.value.data,
        } as GeoLayer);
      }
    });

    return layers;
  };

  return useQuery<GeoLayer[], AxiosError>({
    queryKey: GebietsauswahlQueryKeys.LayersGeoData,
    enabled: daten.length > 0,
    queryFn: () => GetLayersGeoData(api, daten),
    onError: (err) => logAxiosError("Error loading customLayers", "Fehler beim Laden der eigenen Layer", err),
  });
};
