import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

import NotFound from "../assets/404.png";

const NoMatch: React.FC = () => {
  // effekte
  useEffect(() => {
    document.title = "Glasfaser TK";
  }, []);

  return (
    <div className="bg-dark h-100 overflow-hidden">
      <Container className="mt-5 text-white d-flex align-items-center justify-content-center">
        <Row className="mt-0">
          <Col sm={6}>
            <img src={NotFound} style={{ maxHeight: "90%", maxWidth: "90%" }} />
          </Col>
          <Col sm={6}>
            <h1 className="display-4 text-white">Alle Licher aus?</h1>
            <p>
              Der angeforderte Inhalt wurde anscheinend ausgeknipst. Keine Angst. Bei uns bleiben Sie nicht im Dunkeln.
              Sie können einfach unsere Startseite neu aufrufen.
            </p>
            <Button variant="secondary" as={Link} to="/">
              {" "}
              <BsArrowRight className="text-primary" /> Startseite
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NoMatch;
