import React, { useContext } from "react";

import { Footer, FooterListClassname, Header } from "@gelsenwasser/react";

import { AuthContext } from "services/authentication";
import UserSettings from "./UserSettings";
import { useGetUserData } from "domain/meinekontakte/UserQueries";

export const Layout: React.FC = ({ children }) => {
  const authContext = useContext(AuthContext);
  const userData = useGetUserData();

  return (
    <>
      <Header
        noWhiteBackground
        title={
          <>
            <strong>Glasfaser TK</strong>
            <span className="font-weight-light">Portal | {userData.data?.firmaName}</span>
          </>
        }
        environment={process.env.REACT_APP_ENV}
      >
        {authContext.isAuthenticated() && (
          <>
            <UserSettings UserName={`${userData.data?.firstName} ${userData.data?.lastName}`} />
          </>
        )}
      </Header>

      <main className="d-flex flex-column flex-fill outermain bg-gw-light">{children}</main>

      <Footer year={process.env.REACT_APP_YEAR} version={process.env.REACT_APP_VERSION}>
        <ul className={FooterListClassname}>
          <li>
            <a href="https://www.gelsenwasser.de/impressum" target="_blank" rel="noreferrer noopener">
              Impressum
            </a>
          </li>
          <li>
            <a href="https://www.gelsenwasser.de/datenschutz" target="_blank" rel="noreferrer noopener">
              Datenschutz
            </a>
          </li>
        </ul>
      </Footer>
    </>
  );
};

export default Layout;
