import { AxiosError } from "axios";

import { UseQueryResult } from "react-query";

import { log } from "../../common/Logger";
import { QueryFuncData, useGetData } from "../../common/ApiBase";
import { VorgangQueryKeys } from "domain/vorgaenge/VorgangQueries";
import { GebietsauswahlQueryKeys } from "domain/auswahl/AuswahlQueries";

export const useGetAnzahlVorgaenge = (): UseQueryResult<number, AxiosError> => {
  async function getAnzahlVorgaenge({ queryKey }: QueryFuncData): Promise<number> {
    const [, api] = queryKey;
    const response = await api.get("/Vorgaenge/Count");
    log.trace({ obj: response.data }, "got result from Vorgänge count");

    return response.data;
  }

  return useGetData<number, string>(
    getAnzahlVorgaenge,
    "Error loading AnzahlVorgaenge",
    "Fehler beim Laden der Anzahl Vorgaenge",
    VorgangQueryKeys.AnzahlVorgaenge
  );
};

export const useGetAnzahlGebiete = (): UseQueryResult<number, AxiosError> => {
  async function getAnzahlGebiete({ queryKey }: QueryFuncData): Promise<number> {
    const [, api] = queryKey;
    const response = await api.get("/Gebiete/own/Count");
    log.trace({ obj: response.data }, "got result from Gebiete count");

    return response.data;
  }

  return useGetData<number, string>(
    getAnzahlGebiete,
    "Error loading AnzahlGebiete",
    "Fehler beim Laden der Anzahl Gebiete",
    GebietsauswahlQueryKeys.AnzahlGebiete
  );
};
