import React from "react";
import { Col, Row, Jumbotron, Container, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsHouseDoor } from "react-icons/bs";
import Dashboard from "./dashboard/Dashboard";
import { Tile } from "@gelsenwasser/react";

import AnschlussIcon from "../assets/Anschlussauswahl.svg";
import GebietsIcon from "../assets/Gebietsauswahl.svg";

export const Home: React.VFC = () => {
  // hooks
  // states
  // parameter
  // queries und mutationen
  // effekte
  // daten
  const homeItemClass = "d-flex align-items-center";

  // handler

  return (
    <>
      <Container>
        <Breadcrumb className="mt-3 d-print-none">
          <Breadcrumb.Item
            active={false}
            linkAs={Link}
            linkProps={{ to: "/", className: homeItemClass }}
            className={homeItemClass}
          >
            <BsHouseDoor className="mr-2" />
            Home
          </Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <Container>
        <Row className="mt-0">
          <Col md={8}>
            <Jumbotron>
              <h1 className="display-4">
                Willkommen im <strong>Glasfaser TK</strong>Portal
              </h1>
              <p>
                Willkommen auf dem <strong>Glasfaser TK</strong>Portal der GELSENWASSER AG. Wir sind das Team blau-grün.
                Als Partner von Städten und Kommunen bieten wir Lösungen für alle Aufgaben und Herausforderungen der
                Infrastruktur.
              </p>
            </Jumbotron>
          </Col>
          <Col md={4}>
            <Dashboard />
          </Col>
        </Row>
        <Row>
          <Tile title="Gebietsauswahl" toPath="/gebietsauswahl" iconPath={GebietsIcon} />
          <Tile title="Anschlussauswahl" toPath="/anschlussauswahl" iconPath={AnschlussIcon} />
        </Row>
      </Container>
    </>
  );
};

export default Home;
