import React from "react";
import { Link } from "react-router-dom";

import { UseQueryResult } from "react-query";
import { AxiosError } from "axios";

import { Card, Button, Badge, Spinner } from "react-bootstrap";
import { BsExclamationTriangleFill } from "react-icons/bs";

import {
  useGetAnzahlVorgaenge,
  useGetAnzahlGebiete,
} from "./DashboardQueries";

const Dashboard: React.FC = () => {
  // hooks
  // states
  // parameter
  // queries und mutationen
  const anzahlVorgaengeQuery = useGetAnzahlVorgaenge();
  const anzahlGebieteQuery = useGetAnzahlGebiete();

  // effekte
  // daten
  // handler

  const showAnzahlVorgaenge = (query: UseQueryResult<number, AxiosError>) => {
    if (query.isLoading) return <Spinner animation="border" role="status" as="span" />;
    if (query.isError || query.data === undefined)
      return (
        <>
          <BsExclamationTriangleFill className="text-warning" /> Anzahl Vorgänge konnte nicht ermittelt werden
        </>
      );
    return (
      <>
        Es gibt aktuell{" "}
        <Badge variant="success" className="rounded-pill">
          {query.data}
        </Badge>{" "}
        {query.data === 1 ? "Vorgang" : "Vorgänge"}.
      </>
    );
  };

  const showAnzahlGebiete = (query: UseQueryResult<number, AxiosError>) => {
    if (query.isLoading) return <Spinner animation="border" role="status" as="span" />;
    if (query.isError || query.data === undefined)
      return (
        <>
          <BsExclamationTriangleFill className="text-warning" /> Anzahl Gebiete konnte nicht ermittelt werden
        </>
      );
    return (
      <>
        Es gibt aktuell{" "}
        <Badge variant="success" className="rounded-pill">
          {query.data}
        </Badge>{" "}
        Gebiet{query.data !== 1 ? "e" : null}.
      </>
    );
  };

  return (
    <>
      <Card>
        <Card.Header as="h5" className="bg-primary text-white">
          Meine Vorgänge
        </Card.Header>
        <Card.Body>
          <Card.Text>{showAnzahlVorgaenge(anzahlVorgaengeQuery)}</Card.Text>
          <Button size="sm" as={Link} to="/vorgaenge" className="m-1">
            Anzeigen
          </Button>
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Header as="h5" className="bg-primary text-white">
          Meine Gebiete
        </Card.Header>
        <Card.Body>
          <Card.Text>{showAnzahlGebiete(anzahlGebieteQuery)}</Card.Text>
          <Button size="sm" as={Link} to="/gebiete" className="m-1">
            Anzeigen
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default Dashboard;
