import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import './App.scss'

import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";

import "@gelsenwasser/react/lib/Styles/gwag-theme.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";

import { NotificationProvider } from "@gelsenwasser/react";
import { IDENTITY_CONFIG } from "./services/authentication/AuthConst";
// TODO: use from gelsenwasser-package
import { AuthProvider, LogInCallback, LogOutCallback } from "./services/authentication";

// static components
import Layout from "./components/Layout";
import NoMatch from "./domain/NoMatch";
import { ApiContextProvider } from "./services/ApiContext";
import ProtectedRoute from "components/ProtectedRoute";
import { AuswahlContextProvider } from "domain/auswahl/AuswahlContext";
import Main from "domain/Main";
import PageLoader from "components/PageLoader";

const Benutzer = React.lazy(() => import("./domain/meinekontakte/Index"));
const Gebietsauswahl = React.lazy(() => import("./domain/auswahl/gebietsauswahl/Index"));
const Anschlussauswahl = React.lazy(() => import("./domain/auswahl/anschlussauswahl/Index"));
const Vorgaenge = React.lazy(() => import("./domain/vorgaenge/Index"));
const Gebiete = React.lazy(() => import("./domain/gebiete/Index"));

const queryClient = new QueryClient();
export default class App extends React.Component {
  static displayName = App.name;

  render(): JSX.Element {
    return (
      <>
        <AuthProvider config={IDENTITY_CONFIG}>
          <ApiContextProvider>
            <NotificationProvider>
              <QueryClientProvider client={queryClient}>
                <Layout>
                  <Suspense fallback={<PageLoader message="Seite wird geladen" />}>
                    <Switch>
                      {/* Home */}
                      <Route exact path="/" component={Main} />

                      {/* Authentication */}
                      <Route path="/signin-oidc" component={LogInCallback} />
                      <Route path="/signout-oidc" component={LogOutCallback} />

                      {/* Kundenbereich */}
                      <ProtectedRoute path="/benutzer" component={Benutzer} />
                    <ProtectedRoute path="/vorgaenge" component={Vorgaenge} />
                    <ProtectedRoute path="/gebiete" component={Gebiete} />
                      <AuswahlContextProvider>
                        <ProtectedRoute path="/gebietsauswahl" component={Gebietsauswahl} />
                        <ProtectedRoute path="/anschlussauswahl" component={Anschlussauswahl} />
                      </AuswahlContextProvider>
                    
                      {/* Unbekannte Seite */}
                      <Route path="*" component={NoMatch} />
                    </Switch>
                  </Suspense>
                </Layout>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </NotificationProvider>
          </ApiContextProvider>
        </AuthProvider>
      </>
    );
  }
}
