import { Feature, Polygon } from "geojson";
import { LatLngBounds, LatLngExpression, Layer } from "leaflet";
import React, { ReactNode, useContext, useState } from "react";
import { AnschlussMarker, Ort } from "./AuswahlTypes";
interface Props {
  children: ReactNode;
}

interface AuswahlContextProps {
  polygon?: Feature<Polygon>;
  setPolygon: (data: Feature<Polygon> | undefined) => void;
  ausgewaehlteAnschluesse?: AnschlussMarker[];
  setAusgewaehlteAnschluesse: (data: AnschlussMarker[] | undefined) => void;
  layer?: Layer;
  setLayer: (data: Layer | undefined) => void;
  isGebietsauswahl: boolean;
  setIsGebietsauswahl: (x: boolean) => void;
  nameGebiet?: string;
  setNameGebiet: (data: string | undefined) => void;
  showLayerDialog: boolean;
  setShowLayerDialog: (data: boolean) => void;
  benachrichtigungId?: string;
  setBenachrichtigungId: (data: string | undefined) => void;
  ort?: Ort;
  setOrt: (data: Ort | undefined) => void;
  isCustomLayers: boolean;
  setIsCustomLayers: (data: boolean) => void;
  nameVorgang?: string;
  setNameVorgang: (data: string) => void;
  center?: LatLngExpression;
  setCenter: (data: LatLngExpression | undefined) => void;
  bounds?: LatLngBounds;
  setBounds: (data: LatLngBounds | undefined) => void;
  anschlussFilter: string[];
  setAnschlussFilter: (data: string[]) => void;
}

export const AuswahlContext = React.createContext<AuswahlContextProps>({
  polygon: undefined,
  setPolygon: () => 0,
  ausgewaehlteAnschluesse: undefined,
  setAusgewaehlteAnschluesse: () => 0,
  layer: undefined,
  setLayer: () => 0,
  isGebietsauswahl: false,
  setIsGebietsauswahl: () => 0,
  nameGebiet: undefined,
  setNameGebiet: () => 0,
  showLayerDialog: false,
  setShowLayerDialog: () => 0,
  benachrichtigungId: undefined,
  setBenachrichtigungId: () => 0,
  ort: undefined,
  setOrt: () => 0,
  isCustomLayers: true,
  setIsCustomLayers: () => 0,
  nameVorgang: undefined,
  setNameVorgang: () => 0,
  center: undefined,
  setCenter: () => 0,
  bounds: undefined,
  setBounds: () => 0,
  anschlussFilter: [],
  setAnschlussFilter: () => 0
});
export function AuswahlContextProvider(props: Props): React.ReactElement {
  const [layer, setLayer] = useState<Layer>();
  const [polygon, setPolygon] = useState<Feature<Polygon>>();
  const [nameGebiet, setNameGebiet] = useState<string>();
  const [ausgewaehlteAnschluesse, setAusgewaehlteAnschluesse] = useState<AnschlussMarker[]>();
  const [isGebietsauswahl, setIsGebietsauswahl] = useState(false);
  const [showLayerDialog, setShowLayerDialog] = useState(false);
  const [benachrichtigungId, setBenachrichtigungId] = useState<string | undefined>(undefined);
  const [ort, setOrt] = useState<Ort | undefined>();
  const [isCustomLayers, setIsCustomLayers] = useState(true);
  const [nameVorgang, setNameVorgang] = useState<string>();
  const [center, setCenter] = useState<LatLngExpression>();
  const [bounds, setBounds] = useState<LatLngBounds>();
  const [anschlussFilter, setAnschlussFilter] = useState<string[]>([])

  return (
    <AuswahlContext.Provider
      value={{
        polygon,
        setPolygon,
        ausgewaehlteAnschluesse,
        setAusgewaehlteAnschluesse,
        layer,
        setLayer,
        isGebietsauswahl,
        setIsGebietsauswahl,
        nameGebiet,
        setNameGebiet,
        showLayerDialog,
        setShowLayerDialog,
        benachrichtigungId,
        setBenachrichtigungId,
        ort,
        setOrt,
        isCustomLayers,
        setIsCustomLayers,
        nameVorgang,
        setNameVorgang,
        center,
        setCenter,
        bounds,
        setBounds,
        anschlussFilter,
        setAnschlussFilter
      }}
    >
      {props.children}
    </AuswahlContext.Provider>
  );
}

export const useAuswahl = () => {
  return useContext(AuswahlContext);
};
