import React from "react";
import { Spinner } from "react-bootstrap";

interface PageLoaderProps {
  message?: string;
}

const PageLoader = ({ message = "Daten werden geladen" }: PageLoaderProps) => {
  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <Spinner animation="border" role="status" />
      &nbsp;{message}&nbsp;...
    </div>
  );
};

export default PageLoader;
